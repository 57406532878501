@import "../../Styles/resources";

.home-new-slide {
  // margin-bottom: 80px;
  border-bottom: 10px solid #2b3513;
  
  .swiper {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .swiper-slide {
    font-size: 18px;
    color: #fff;
    box-sizing: border-box;
    padding: 40px 60px;
    height: 700px !important;
    
    @include mobile-6 {
      padding: 20px 30px;
      height: 400px !important;
    }
  }

  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    background-size: 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    transition: background-image 0.6s ease-in-out, transform 0.6s ease-in-out;
  }

  .narration-con {
    background-color: #fff;
    color: #2b3513;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    width: 40%;
    position: absolute;
    left: 50px;
    bottom: 50px;
    opacity: 0.9;
    transition: transform 0.6s ease-in-out;
    
    @include mobile-4 {
      left: 50px;
      bottom: 100px;
    }

    @include mobile-6 {
      width: 70%;
    }

    .narration-banner {
      background-color: #2b3513;
      color: #fff;
      font-size: 20px;
      padding: 5px;
      text-align: center;
      transition: transform 0.6s ease-in-out;
    }
    
    .narration-text {
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      transition: transform 0.6s ease-in-out;
      
      @include mobile-4 {
        font-size: 12px;
      }
    }
    
    .centered-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;
      
      button {
        border-radius: 5px;
        padding: 10px 20px;
        color: #fff;
        background-color: #000;
        outline: none;
        border: none;
        cursor: pointer;
        transition: transform 0.6s ease-in-out;
      }
    }
  }
}

.looping-message {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  // margin-top: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;

  .scrolling-message {
    white-space: nowrap;
    display: inline-block;
    animation: scrollLeft 10s linear infinite;
    
    p {
      font-size: 16px;
      color: #2b3513;
      font-weight: 500;
      display: inline;
      padding: 0 10px;
    }
  }

  .centered-buttonn {
    margin-top: 10px;

    button {
      border-radius: 5px;
      padding: 10px 20px;
      color: #fff;
      background-color: #2b3513;
      outline: none;
      border: none;
      cursor: pointer;
      transition: transform 0.6s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

