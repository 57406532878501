@import "../../Styles/resources";

.new-events {
  .topBg {
    min-height: 50vh;
    min-height: 50vh;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("../../images/new-images/EventBgImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    h1 {
      font-size: 50px;
      margin: 30px 0px;
      font-weight: 400;
    }
    p {
      font-size: 30px;
    }
  }
  .event-options {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    @include mobile-6 {
      flex-direction: column;
      gap: 20px;
      margin: 20px 0px;
    }

    .span-active {
      transition: all 0.4s ease-in-out;
      font-weight: 700;
      font-size: 25px;
      color: #2b3513;
      margin-right: 20px;
      // cursor: pointer;
    }
    .span-inactive {
      font-weight: 700;
      color: #717861;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .events-header {
    font-size: 40px;
    margin: 20px 0px 40px 0px;
    font-weight: 400;
    text-align: center;
    color: #2b3513;
  }

  .event-container {
    padding: 50px 100px;
    @include mobile-1 {
      padding: 30px;
    }
  }
  .event-items {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0px;
    button {
      border: 1px solid #2b3513;
      padding: 10px 30px;
      outline: none;
      background-color: transparent;
      border-radius: 20px;
      color: #2b3513;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
