@import "../../Styles/resources";
.article {
  .news_main {
    display: flex;
    justify-content: center;
    background-color: #fff;
    font-family: "Roboto";
    .cover {
      width: 100%;
      max-width: 1300px;
      display: flex;
      justify-content: space-between;
      padding: 100px 30px;
      flex-wrap: wrap;
      @include mobile-1 {
        padding: 100px 20px;
      }
      .left {
        width: 65%;
        background-color: #fff;
        height: inherit;
        max-height: 80vh;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include mobile-1 {
          width: 100%;
          height: fit-content;
        }
      }
      .right {
        width: 30%;
        @include mobile-1 {
          width: 100%;
          padding-top: 50px;
        }
        .top {
          border-left: 5px solid #2b3513;
          margin-bottom: 32px;
          padding-left: 10px;
        }
        h2 {
          color: #4a4a4a;
          font-size: 30px;
        }
        .wrap {
          .card {
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 20px;
            button {
              background: none;
              padding: 7px 20px;
              color: #2b3513;
              font-size: 10px;
              border: 1px solid #2b3513;
              border-radius: 40px;
              margin-bottom: 8px;
              font-weight: 700;
              cursor: pointer;
            }
            .flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;
              h3 {
                color: #2b3513;
                font-size: 16px;
                width: 90%;
              }
              svg {
                font-size: 16px;
                cursor: pointer;
              }
            }
            p {
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #4e5665;
              font-size: 14px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
