.scroll-to-top {
  position: fixed;
  left: 30px;
  bottom: 30px;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
  color: #2b3513;
  border-radius: 50%;
  outline: none;
  box-shadow: 5px 8px 10px #000;
  border: none;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
