@import '../../../Styles/resources';

.gallery-details{

    .hero_Image {
        height: 40vh;
        max-height: 424px;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
          url('../../../images/new-images/InsightBgImg.png');
        background-size: cover;
        background-position: center;
        display: flex;
        // justify-content: flex-end;
        // align-items: flex-end;
        @include mobile-1 {
          height: fit-content;
        }
    
        .cover {
          width: 500px;
          padding: 50px 0;
          color: #fff;
          font-family: 'Roboto', sans-serif;
          max-width: 500px;
          width: 100%;
          padding: 50px 20px;
          h1 {
            font-size: 50px;
            padding-bottom: 10px;
            font-weight: 400;
            @include mobile-1 {
              padding-top: 10vh;
            }
          }
          p {
            font-weight: 400;
            font-size: 20px;
            padding-bottom: 30px;
          }
          button {
            background: none;
            padding: 10px 30px;
            color: #fff;
            font-size: 16px;
            border: 1px solid #2b3513;
            font-family: 'Roboto';
            cursor: pointer;
          }
        }
      }

    .gallery-con{
        padding: 0px 40px;
        margin: 80px 0px;

        .gallery-header{
            font-size: 40px;
            color: #2B3513;
            margin: 30px 0px;
        }


    .gallery-items{
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 40px;

        @include mobile-2{
                grid-template-columns: auto;
        }

        .gallery-item{
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

            .top{
                width: 100%;
                height: 300px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .bottom{
                padding: 20px;
                p{
                    color: #2B3513;
                    font-size: 16px;
                }
            }
        }
    }
    }
}