.sharedbutton {
  border: 1px solid #2b3513;
  width: 120px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  font-size: 16px;
  gap: 10px;
  cursor: pointer;
  color: #717861;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sharedbutton.whiteoval {
  border: 1px solid #ffffff;
  background: transparent;
  color: #ffffff;
  border-radius: 20px;
  padding: 10px;
  width: 250px;
}
.sharedbutton.darkoval {
  border: 1px solid #2b3513;
  background: transparent;
  color: #2b3513;
  border-radius: 20px;
  padding: 10px;
  width: 150px;
}
.sharedbutton.graybackground {
  border: none;
  background: #717861;

  color: #ffffff;
  border-radius: 20px;
  padding: 10px;
  width: 200px;
}
.sharedbutton.graysquarebackground {
  border: none;
  background: #717861;

  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
}

.sharedbutton.graybackground100 {
  border: none;
  background: #717861;

  color: #ffffff;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
}

.small {
}
