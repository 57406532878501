@import "../../Styles/resources";

.modal-cover {
  position: relative;
  height: 600px;
  overflow-y: auto;
  .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  background-color: #fff;
  margin: 30px;
  // max-width: 400px;
  padding: 30px;
  .top {
    padding-bottom: 30px;
    @include mobile-3 {
      padding-bottom: 10px;
    }
    h2 {
      color: #505050;
      font-weight: 500;
      font-size: 22px;
      padding-bottom: 20px;
      @include mobile-3 {
        padding-bottom: 10px;
        font-size: 16px;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      color: #505050;
      line-height: 19px;
      @include mobile-3 {
        font-size: 10px;
      }
    }
  }
  .agm {
    margin: 30px 0px;
    .agm-select-card {
      width: 100%;
      margin-bottom: 24px;
      @include mobile-3 {
        margin-bottom: 10px;
      }
      small {
        font-size: 12px;
        font-weight: 500;
      }

      h4 {
        color: #505050;
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 5px;
      }

      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        height: 50px;
        padding: 0 20px;
        outline: none;
      }
    }
  }
  form {
    .card {
      width: 100%;
      margin-bottom: 24px;
      @include mobile-3 {
        margin-bottom: 10px;
      }
      h4 {
        color: #505050;
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 5px;
      }
      input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        height: 50px;
        padding: 0 20px;
        outline: none;
      }
      select {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        height: 50px;
        padding: 0 20px;
        outline: none;
      }
      textarea {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        padding: 10px 20px;
        resize: none;
        outline: none;
      }
      p {
        padding-bottom: 5px;
        color: #505050;
        font-weight: 400;
        font-size: 14px;
      }
      ul {
        padding-top: 10px;
        li {
          color: #505050;
          font-weight: 700;
          font-size: 14px;
          padding-bottom: 15px;
        }
      }
      button {
        width: 100%;
        background: #2b3513;
        border-radius: 500px;
        padding: 5px 0;
        color: #fff;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    .remove-btn {
      padding: 5px;
      background-color: #d3d3d3;
      color: #2b3513;
      outline: none;
      border: 1px solid #2b3513;
      cursor: pointer;
      border-radius: 5px;
    }

    .participant-section {
      margin: 20px 0px;
      background-color: #ddd;
      padding: 20px 0px;
    }

    .diff-btn {
      margin: 20px 0px;
      justify-content: center;
      align-items: center;
      div {
        text-align: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
        p {
          font-size: 14px;
          color: #2b3513;
        }
      }
    }
  }
}

.event-card {
  box-shadow: 0px 10px 20px #d3d3d3;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  width: 30%;
  @include mobile-3 {
    width: 100%;
  }
  @include mobile-4 {
    width: 100%;
  }
  @include mobile-6 {
    width: 45%;
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  p {
    color: #000022;
    font-size: 12px;
    padding-left: 5px;
  }
  .icons {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    svg {
      font-size: 15px;
    }
  }
  h2 {
    color: #000022;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      padding: 6px 20px;
      background: #717861;
      border-radius: 5px;
      border: 1px solid #717861;
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
      &.light {
        background: none;
        color: #717861;
      }
    }
  }
}
