@import "../../Styles/resources";

.new-about {
  .topBg {
    min-height: 100vh;
    min-height: 110vh;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
  }

  .new-about-content {
    position: relative;
    padding: 20px 30px;
    background-color: #eeeeee;

    @include mobile-4 {
      padding: 20px 10px;
    }

    .endeavour-items {
      margin: 40px 0px;
      display: grid;
      gap: 30px;
      color: #2b3513;
      grid-template-columns: auto auto;
      @include mobile-2 {
        grid-template-columns: auto;
      }

      .endeavour-item {
        display: flex;
        gap: 20px;
        h1 {
          font-size: 40px;
          color: #2b3513;
        }
        p {
          line-height: 25px;
        }
      }
    }

    .overlapModal {
      position: relative;
      padding: 30px;
      border-radius: 10px;
      margin: 0px 50px 0px 50px;
      text-align: center;
      background-color: #fff;
      top: -100px;

      @include mobile-3 {
        margin: 0px;
      }

      h1 {
        font-weight: 400;
        margin: 20px 0px;
      }

      p {
        line-height: 25px;
        margin: 10px 0px;
      }
    }
    .history {
      display: flex;
      gap: 20px;
      background-color: transparent;
      margin: 80px 0px;

      @include mobile-1 {
        flex-direction: column;
      }
      .left {
        flex: 1;
        border-radius: 10px;
        // @include mobile-6 {
        //   height: 300px;
        // }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 10px;
        }
      }
      .right {
        flex: 2;
        h3 {
          color: #2b3513;
          font-size: 25px;
          margin: 20px 0px;
        }
        p {
          font-size: 18px;
          margin: 20px 0px;
          color: #2b3513;
        }
      }
    }
  }

  .core-values {
    margin: 60px 0px;
    h1 {
      color: #2b3513;
      margin: 20px 0px;
      text-align: center;
      font-size: 35px;
    }

    .core-values-items {
      display: grid;
      gap: 40px;
      margin: 30px 0px;
      grid-template-columns: auto auto auto;

      @include mobile-6 {
        grid-template-columns: auto;
      }

      .core-values-item {
        display: flex;
        gap: 20px;
        padding: 20px;
        align-items: center;
        color: #fff;
        justify-content: center;
        align-content: center;
        align-items: center;

        &.centered {
          grid-column: 1 / 4;
          @include mobile-6 {
            grid-column: 1;
          }
          .right {
            h5 {
              margin: 10px 0px;
              font-size: 24px;
            }
            p {
              margin: 10px 0px;
              line-height: 25px;
            }
            .uppercase-first::first-letter {
              font-size: 50px;
              line-height: 0.75;
            }
          }
        }

        h3 {
          font-size: 35px;
        }

        .right {
          h5 {
            margin: 10px 0px;
            font-size: 24px;
          }
          p {
            margin: 10px 0px;
            line-height: 25px;
          }
          .uppercase-first::first-letter {
            font-size: 50px;
            line-height: 0.75;
          }
        }
      }
    }
  }

  .missvis {
    padding: 50px;
    display: flex;
    justify-content: center;
    gap: 30px;
    background-color: #343a40;

    @include mobile-6 {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }

    .missvis-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      .top {
        height: 300px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .bottom {
        padding: 20px;
        text-align: center;

        h1 {
          color: #2b3513;
          margin: 20px 0px;
        }
        p {
          margin: 10px 0px;
          color: #2b3513;
          font-size: 14px;
        }
      }
    }
  }

  .our-obj {
    margin: 60px 0px;
    h1 {
      color: #2b3513;
      text-align: center;
      font-size: 35px;
      margin: 30px 0px;
    }
    h4 {
      font-weight: 400;
      color: #2b3513;
      text-align: center;
      margin: 20px 0px;
    }
    p {
      font-size: 16px;
      margin: 20px 0px;
    }

    .our-obj-items {
      margin: 50px 0px;
      display: grid;
      gap: 50px;
      grid-template-columns: auto auto;

      @include mobile-6 {
        grid-template-columns: auto;
      }

      .our-obj-item {
        border-radius: 10px;
        color: #fff;

        &.centered {
          grid-column: 1 / 3;
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            width: 50%;
            border-radius: 10px;
          }
          @include mobile-6 {
            grid-column: 1;
            div {
              width: 100%;
            }
          }
        }

        p {
          font-size: 16px;
          padding: 20px;
        }
      }
    }
  }
  .how-work {
    margin: 60px 0px;
    background-color: #343a40;
    padding: 20px;

    h1 {
      font-size: 35px;
      font-weight: 400;
      color: #fff;
      margin: 30px 0px;
      text-align: left;
    }

    .how-work-con {
      margin: 30px 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      justify-content: space-evenly;
      color: #fff;
      text-align: left;
    }
  }
  .nat-council {
    margin: 60px 0px;
    .core-values-items {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      margin: 30px 0px;

      @include mobile-6 {
        display: grid;
        grid-template-columns: auto;
      }

      .core-values-item {
        display: flex;
        gap: 20px;
        padding: 10px;
        align-items: center;
        color: #fff;

        width: calc((100% - 40px) / 2);
        @include mobile-6 {
          width: 100%;
        }

        h3 {
          font-size: 35px;
        }

        .right {
          h5 {
            margin: 10px 0px;
          }
          p {
            margin: 10px 0px;
            line-height: 25px;
          }
        }
      }
    }
    p {
      margin: 30px 0px;
      font-size: 16px;
    }
  }

  .ad-hoc {
    margin: 20px 0px;
    padding: 30px;
    background-color: #343a40;
    color: #fff;
    h1 {
      font-weight: 400;
      margin: 30px 0px;
    }
    p {
      margin: 20px 0px;
      font-size: 16px;
      line-height: 25px;
    }
  }

  .other-groups {
    margin: 10px 0px;
    padding: 20px;
    color: #2b3513;

    h1 {
      font-weight: 400;
      margin: 10px 0px;
    }
    p {
      margin: 20px 0px;
      font-size: 16px;
      line-height: 25px;
      width: 70%;

      @include mobile-6 {
        width: 100%;
      }
    }
  }

  .exec-committe {
    margin: 60px 0px;
    padding: 30px;
    background-color: #343a40;
    color: #fff;

    .exec-committe-items {
      margin: 20px 0px;
      @include mobile-6 {
        grid-template-columns: auto;
      }
      display: grid;
      grid-template-columns: auto auto;
      gap: 30px;

      .exec-committe-item {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        h6 {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    h1 {
      font-weight: 400;
      margin: 30px 0px;
    }
    p {
      margin: 20px 0px;
      font-size: 16px;
      line-height: 25px;

      @include mobile-6 {
        width: 100%;
      }
    }
  }
}
