@import "../../Styles/resources";
.navbar {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    width: 100px;
    cursor: pointer;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .navlist {
    position: relative;
    display: flex;

    .toggle-navitem {
      margin-right: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 14px;
        color: #1f260d;
      }
      .special-span {
        font-size: 14px;
        color: #1f260d;
        &:hover {
          color: #2baab1;
        }
      }
    }

    @include mobile-1 {
      display: none;
    }

    .translate-icon {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #1f260d;
      padding-left: 10px;
    }

    .join-now {
      margin-left: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        padding: 10px 30px;
        outline: none;
        border: 1px solid #1f260d;
        color: #1f260d;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;

    @include mobile-1 {
      display: block;
    }
  }
}
