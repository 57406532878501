@import "../../Styles/resources";

.paid-publications {
  .header {
    margin: 20px 0px;
    color: #2b3513;
    text-align: center;
  }
  .paid-publication-items {
    padding: 30px 50px;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @include mobile-6 {
      padding: 10px 20px;
    }
  }
}
