.back-button {
  display: flex;
}
.back-button span {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Ceremonycontainer {
  width: 100%;
}
.Ceremonycontainer .container {
  color: #2b3513;
  width: 85%;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

.Ceremonycontainer .imagecontainer {
  width: 90%;
  /* border: 1px solid red; */
  margin: 0 auto;
}
.Ceremonycontainer .imagecontainer video {
  width: 100%;
  height: 100%;
}
