.dash-sidebar {
  width: 200px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0px;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }

  .sideItems {
    .sideItem {
      padding: 20px;
      color: #000;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin: 20px 0px;
      cursor: pointer;

      &.active {
        background-color: #2b3513;
        color: #fff;
      }
      svg {
        margin-right: 10px;
      }
      span {
        font-size: 18px;
      }
    }
  }
}
