.Homecontainer {
  width: 100%;
}

.Homecontainer .homeheader {
  width: 100%;
  margin-bottom: 60px;
}
.Homecontainer .homeheader img {
  height: 100%;
  width: 100%;
}

.Homecontainer .homeimages p {
  cursor: pointer;
}

.Homecontainer .homeimages button {
  border: none;
  background: transparent;
  cursor: pointer;
}
.Homecontainer .hometext {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: left;
}
.Homecontainer .hometext h2 {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 35px;
}

.Homecontainer .hometext p {
  line-height: 20px;
}
.Homecontainer .homeimages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
}
.Homecontainer .homeimages .images {
  width: 100%;
  height: 80%;
  margin-bottom: 20px;
}

.Homecontainer .buttoncontainer {
  width: 35%;
  margin: 0 auto;
}
.Homecontainer .homeheader {
  width: 100%;
  /* border: 1px solid red; */
}

.Homecontainer .homeheader .homeheaderpicture {
  padding: 50px 0px;
  /* background-image: url("../../assets/reusedimages/Homeimages/homeheader.jpeg"); */
  /* padding: 100px; */
  /* background-repeat: no-repeat;

  background-size: 100%; */
  /* max-width:  1508px; */
  /* height: 804px; */
}
.Homecontainer .homeheader .homeheadertext {
  padding: 40px;
  width: 70%;
  padding-top: 100px;
}
.Homecontainer .homeheader .homeheadertext h1 {
  color: #ffffff;
  font-size: 50px;
  font-weight: 500;
  font-family: "cursive";
}

.homeheadertext p {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  /* .Homecontainer .homeheader .homeheaderpicture {
    height: 500px;
  } */
  .Homecontainer .homeheader .homeheadertext {
    padding: 40px;

    width: 70%;
  }
}
@media screen and (max-width: 1200px) {
  .Homecontainer .homeheader .homeheadertext h1 {
    color: #ffffff;
    font-size: 45px;
    font-weight: 700;
  }
}
@media screen and (max-width: 900px) {
  .Homecontainer .homeheader .homeheadertext h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
  }
}

@media screen and (max-width: 753px) {
  .Homecontainer .homeheader .homeheadertext h1 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
  }
  .Homecontainer .homeimages {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
@media screen and (max-width: 650px) {
  .Homecontainer .homeheader .homeheadertext h1 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
  }
  .Homecontainer .homeimages {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 450px) {
  .Homecontainer .homeheader .homeheadertext h1 {
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
  }
}
