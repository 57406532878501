@import "../../Styles//resources";
.members {
  font-family: "Roboto";
  .search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;

    input {
      padding: 10px 20px;
      width: 300px;
    }
  }
  .hero_image {
    height: 50vh;
    max-height: 424px;
    background: url("../../images/new-images/TeamworkImg.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    &.exist {
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url("../../images/Rectangle\ 2\ \(1\).png");
    }
    &.latest {
      background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url("../../images/new-images/MemberRequirement.png");
    }
    @include mobile-3 {
      padding: 50px 20px;
    }
    .cover {
      h2 {
        color: #2b3513;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 40px;
        @include mobile-3 {
          font-size: 14px;
        }
      }
      h1 {
        color: #fff;
        font-size: 60px;
        font-weight: 400;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 30px;
        }
      }
      p {
        color: #fff;
        font-size: 25px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }
  .members_tab {
    text-align: center;
    padding: 30px;

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      a {
        text-decoration: none;
      }
      li {
        list-style: none;
        color: #b0b0b0;
        padding-right: 70px;
        font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        padding-bottom: 10px;
        &:hover {
          color: #2b3513;
        }
        &.active {
          color: #2b3513;
        }
      }
    }
  }
  .why {
    padding: 100px 100px;
    color: #2b3513;
    @include mobile-1 {
      padding: 50px 20px;
    }
    .man {
      h1 {
        font-size: 30px;
        color: #2b3513;
        padding-bottom: 24px;
      }
      img {
        width: 100%;
      }
      p {
        color: #2b3513;
        font-size: 20px;
        padding-top: 24px;
      }
    }
    .point {
      padding-top: 100px;
      .card {
        display: flex;
        list-style: none;
        padding-bottom: 40px;
        .left {
          margin-right: 10px;
          img {
            width: 20px;
          }
        }
        .right {
          h1 {
            font-size: 25px;
            color: #2b3513;
            padding-bottom: 20px;
          }
          p {
            color: #2b3513;
            font-size: 20px;
          }
        }
      }
    }
    .benefits {
      padding-top: 50px;
      .top {
        h1 {
          text-align: center;
          font-size: 30px;
          color: #2b3513;
          padding-bottom: 100px;
        }
      }
      .row1,
      .row2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          &.up {
            margin-top: 200px;
          }
          @include mobile-3 {
            width: 100%;
            padding-bottom: 30px;
            &.up {
              margin-top: 0px;
            }
          }
          img {
            width: 210px;
            height: 210px;
          }
          p {
            padding-top: 25px;
            color: #2b3513;
            font-size: 20px;
          }
        }
      }
    }
    .respo {
      padding: 100px 0;
      h1 {
        font-size: 30px;
        color: #2b3513;
        padding-bottom: 20px;
      }
      p {
        color: #2b3513;
        font-size: 20px;
      }
    }
    .acrds {
      .card {
        display: flex;
        list-style: none;
        padding-bottom: 40px;
        .left {
          margin-right: 10px;
          img {
            width: 20px;
          }
        }
        .right {
          h1 {
            font-size: 25px;
            color: #2b3513;
            padding-bottom: 20px;
          }
          p {
            color: #2b3513;
            font-size: 20px;
            line-height: 40px;
          }
        }
      }
    }
  }
  .rquire {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../images/Rectangle 2.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding: 50px;
    margin: 80px 0px;
    .over {
      text-align: center;
      h1 {
        color: #fff;
        font-weight: 500;
        font-size: 30px;
        padding-bottom: 30px;
      }
      button {
        border: 1px solid #fff;
        border-radius: 100px;
        padding: 15px 20px;
        background: none;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .faq {
    display: flex;
    justify-content: center;
    font-family: "Roboto";
    .faq-wrap {
      max-width: 1120px;
      width: 100%;
      padding: 50px 0;
      @include mobile-1120px {
        padding: 50px 20px;
      }
      .top {
        text-align: center;
        padding-bottom: 56px;
        h1 {
          font-weight: 600;
          font-size: 40px;
          line-height: 130%;
          color: #555d42;
          padding-bottom: 17px;
          @include mobile-5 {
            font-size: 30px;
          }
        }
      }
      .listt {
        display: flex;
        justify-content: center;
        .list {
          width: 100%;
          max-width: 1000px;
          .card {
            height: 70px;
            overflow: hidden;
            &.active {
              height: fit-content;
              overflow: unset;
            }
            header {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #f1f1f1;
              min-height: 70px;
              align-items: center;
              padding: 10px 0;
              h2 {
                font-weight: 500;
                font-size: 20px;
                line-height: 100%;
                color: #141010;
                @include mobile-5 {
                  font-size: 20px;
                }
              }
            }
            article {
              padding: 40px 0;
              font-weight: 300;
              font-size: 20px;
              line-height: 130%;
              color: #141010;
              @include mobile-5 {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .new_member {
    display: flex;
    justify-content: space-between;
    padding: 100px 30px;
    flex-wrap: wrap;
    @include mobile-1 {
      padding: 50px 20px;
    }
    .left {
      width: 50%;
      @include mobile-1 {
        width: 100%;
      }
      .xard {
        margin-bottom: 50px;
      }
      h2 {
        font-weight: 700;
        font-size: 25px;
        color: #2b3513;
        padding-bottom: 15px;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        color: #2b3513;
        padding-bottom: 30px;
      }
      button {
        background-color: #2b3513;
        color: #fff;
        padding: 15px 25px;
        border-radius: 500px;
        border: none;
        cursor: pointer;
      }
      ul {
        li {
          padding-bottom: 10px;
          font-weight: 400;
          font-size: 18px;
          color: #2b3513;
        }
      }
      .hey {
        li {
          list-style: none;
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          p {
            font-size: 16px;
            padding-bottom: 0;
          }
          svg {
            color: #2b3513;
            margin-right: 20px;
          }
        }
      }
      .she {
        ul {
          padding-bottom: 20px;
          p {
            font-size: 14px;
            padding-bottom: 10px;
          }
          li {
            font-size: 14px;
            text-transform: lowercase;
            margin-left: 20px;
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .right {
      width: 35%;
      border-top: 5px solid #2b3513;
      padding: 30px;
      @include mobile-1 {
        width: 100%;
        padding: 30px 10px;
      }
      .top {
        padding-bottom: 30px;
        h2 {
          color: #505050;
          font-weight: 700;
          font-size: 22px;
          padding-bottom: 40px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          color: #505050;
          line-height: 19px;
        }
      }
      form {
        .card {
          width: 100%;
          margin-bottom: 24px;
          h4 {
            color: #505050;
            font-size: 16px;
            padding-bottom: 5px;
          }
          input {
            width: 100%;
            background: #ffffff;
            border: 1px solid #d3d3d3;
            border-radius: 5px;
            height: 50px;
            padding: 0 20px;
          }
          p {
            padding-bottom: 5px;
            color: #505050;
            font-weight: 400;
            font-size: 14px;
          }
          ul {
            padding-top: 10px;
            li {
              color: #505050;
              font-weight: 700;
              font-size: 14px;
              padding-bottom: 15px;
            }
          }
          button {
            width: 100%;
            background: #2b3513;
            border-radius: 500px;
            padding: 15px 0;
            color: #fff;
            border: none;
            cursor: pointer;
          }
        }
      }
      .bottom {
        p {
          padding-top: 10px;
          color: #717861;
          font-size: 16px;
        }
      }
    }
  }
  .wrapper {
    display: flex;
    justify-content: center;
    .cover {
      width: 100%;
      max-width: 1200px;
      padding: 20px;
      h1 {
        font-weight: 400;
        font-size: 30px;
        color: #2b3513;
        padding-bottom: 30px;
        margin: 40px 0px;
      }
      .flex {
        display: grid;
        grid-template-columns: auto auto auto;
        padding-bottom: 50px;
        gap: 40px;

        @include mobile-2 {
          grid-template-columns: auto;
        }
        .card {
          margin-bottom: 20px;
          padding: 20px;
          word-break: break-all;
          border-radius: 10px;
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

          @include mobile-2 {
            width: 100%;
          }

          .card-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 10px 0px;
            color: #2b3513;

            .bold {
              font-weight: 700;
            }
            .light {
              margin-left: 10px;
              a {
                word-break: break-all;
              }
            }
          }
        }
      }
      .top_search {
        display: flex;
        justify-content: center;
        .search {
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 20px;
          input {
            border: none;
            height: 50px;
            width: 95%;
            padding: 0 20px;
            outline: none;
          }
        }
      }
      .main_body {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        .left {
          width: 90%;
          .flex {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 50px;
            .card {
              width: 24%;
              margin-bottom: 20px;
              height: inherit;
              background-color: unset;
              img {
                width: 100%;
              }
              @include mobile-4 {
                width: 100%;
              }
            }
          }
        }
        .right {
          ul {
            li {
              list-style: none;
              padding-bottom: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .pagination {
    overflow: auto;
    padding: 10px;
    margin: 30px 0px;

    span {
      margin: 10px;
      background-color: #b0b0b0;
      border-radius: 10px;
      padding: 5px;
      cursor: pointer;
      color: #2b3513;
      font-weight: 500;
    }
  }
}
