@import "../../Styles/resources";
.services-services {
  .services-btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 80px 0px;
    button {
      border: 1px solid #2b3513;
      padding: 10px 30px;
      outline: none;
      background-color: transparent;
      border-radius: 20px;
      color: #2b3513;
      font-size: 18px;
      cursor: pointer;
    }
  }
  font-family: "Poppins";
  .services-hero_image {
    height: 50vh;
    max-height: 424px;
    background: url("../../images/new-images/MANHOUSE (2).png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
    .services-cover {
      h2 {
        color: #2b3513;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 40px;
        @include mobile-3 {
          font-size: 14px;
        }
        @include mobile-1 {
          padding-top: 30px;
        }
      }
      h1 {
        color: #fff;
        font-size: 60px;
        font-weight: 400;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 30px;
        }
      }
      p {
        color: #fff;
        font-size: 25px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }
  .services-new_member {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 50px;
    @include mobile-3 {
      padding: 50px 0;
    }
    .services-left {
      width: 50%;
      @include mobile-1 {
        width: 100%;
      }
    }
    .services-right {
      width: 70%;
      border-top: 5px solid #2b3513;
      padding: 30px;
      @include mobile-1 {
        width: 100%;
        padding: 30px 10px;
      }
      .services-top {
        padding-bottom: 30px;
        h2 {
          color: #505050;
          font-weight: 700;
          font-size: 22px;
          padding-bottom: 40px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          color: #505050;
          line-height: 19px;
        }
      }
      form {
        .services-card {
          width: 100%;
          margin-bottom: 24px;
          h4 {
            color: #505050;
            font-size: 16px;
            padding-bottom: 5px;
          }
          input {
            width: 100%;
            background: #ffffff;
            border: 1px solid #d3d3d3;
            border-radius: 5px;
            height: 50px;
            padding: 0 20px;
          }
          textarea {
            width: 100%;
            background: #ffffff;
            border: 1px solid #d3d3d3;
            border-radius: 5px;
            padding: 10px 20px;
            resize: none;
          }
          p {
            padding-bottom: 5px;
            color: #505050;
            font-weight: 400;
            font-size: 14px;
          }
          ul {
            padding-top: 10px;
            li {
              color: #505050;
              font-weight: 700;
              font-size: 14px;
              padding-bottom: 15px;
            }
          }
          button {
            width: 100%;
            background: #2b3513;
            border-radius: 500px;
            padding: 15px 0;
            color: #fff;
            border: none;
            cursor: pointer;
          }
        }
      }
      .services-bottom {
        p {
          padding-top: 10px;
          color: #717861;
          font-size: 16px;
        }
      }
    }
  }
  .services-newservices {
    padding: 50px 50px;

    @include mobile-6 {
      padding: 20px;
    }

    .services-head {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      margin: 50px 0px;
      h1 {
        font-size: 30px;
        color: #2b3513;
        padding-bottom: 20px;
      }
      p {
        width: 80%;
        color: #343334;
        font-size: 20px;
      }
    }
    .services-wrap {
      display: flex;
      // justify-content: space-evenly;
      flex-wrap: wrap;
      padding-bottom: 50px;
      gap: 5px;

      @include mobile-6 {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
      }

      .services-card {
        width: 32%;
        @include mobile-6 {
          width: 100%;
        }
        margin-bottom: 30px;
        min-height: inherit;
        background-size: cover;
        display: flex;
        justify-content: center;

        .services-overlay {
          width: 80%;
          background: rgba(113, 120, 97, 0.7);
          margin-bottom: 30px;
          padding: 30px;
          color: #fff;
          @include mobile-3 {
            padding: 30px 20px;
            width: 100%;
            margin-bottom: 0;
          }
          h1 {
            font-size: 20px;
            text-transform: capitalize;
          }
          p {
            font-size: 12px;
            font-weight: 300;
            padding-bottom: 10px;
          }
        }
      }
      .services-button {
        width: 100%;
        text-align: center;
        button {
          width: 100%;
          background: #2b3513;
          border-radius: 500px;
          padding: 15px 20px;
          color: #fff;
          border: none;
          cursor: pointer;
          width: fit-content;
        }
      }
    }
  }
}
