@import "../../Styles/resources";

.insight-more {
  // Updated grid layout for the .insight-below section
  .insight-below {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 30px;
    gap: 30px;

    // Adjust grid for different screen sizes
    @include mobile-1300 {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mobile-6 {
      grid-template-columns: 1fr;
    }
  }

  // Individual item styling inside the insight-below grid
  .insight-below-item {
    background-color: #fff;

    // Image styling
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    // Text styling
    .text {
      padding: 20px;

      // Title (h2) styling
      h2 {
        color: #2b3513;
        font-weight: 400;
        margin: 20px 0;
      }

      // Subtitle (h3) styling
      h3 {
        color: #63c1cf;
        font-size: 14px;
        font-weight: 400;
      }

      // Paragraph (p) styling
      p {
        font-size: 16px;
        color: #2b3513;
        margin: 20px 0 50px 0;
      }

      // Link styling
      a {
        text-decoration: none;
        color: #505050;
      }
    }
  }

  // Background color for the section
  background-color: #f3f4f7;

  // Hero image section styling
  .hero_Image {
    height: 40vh;
    max-height: 424px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../images/new-images/InsightBgImg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include mobile-1 {
      height: fit-content;
    }

    // Hero text and button styles
    .cover {
      //padding: 50px 0;
      color: #fff;
      font-family: "Roboto", sans-serif;
      //max-width: 600px;
      //width: 100%;
      //padding: 50px 20px;
      padding: 20px;
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;

      // Hero title
      h1 {
        font-size: 50px;
        padding-bottom: 10px;
        font-weight: 400;

        @include mobile-1 {
          padding-top: 10vh;
        }
      }

      // Hero paragraph
      p {
        font-weight: 400;
        font-size: 20px;
        padding-bottom: 30px;
      }

      // Button styling
      button {
        background: none;
        padding: 10px 30px;
        color: #fff;
        font-size: 16px;
        border: 1px solid #2b3513;
        font-family: "Roboto";
        cursor: pointer;
      }
    }
  }

  // News section styling
  .news_main {
    display: flex;
    justify-content: center;
    font-family: "Roboto";

    // Main content wrapper
    .cover {
      width: 100%;
      max-width: 1300px;
      display: flex;
      justify-content: space-between;
      padding: 100px 30px;
      flex-wrap: wrap;

      @include mobile-1 {
        padding: 100px 20px;
      }

      // Left column for main content
      .left {
        width: 65%;
        height: 100%;
        margin-top: 200px;

        // Image in the left section
        img {
          width: 100%;
          height: 70vh;
          object-fit: cover;
        }

        // Section wrapper for cards or additional content
        .sect_wrap {
          .coverr {
            width: 100%;
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            // Individual cards inside left section
            .card {
              width: 48%;
              margin-bottom: 30px;
              background: #fff;
              position: relative;

              @include mobile-1 {
                width: 45%;
              }

              @include mobile-2 {
                width: 100%;
              }

              // Card image
              img {
                width: 100%;
                height: 300px;
              }

              // Card text content
              .text {
                padding: 10px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                // Card title
                h2 {
                  font-weight: 500;
                  color: #63c1cf;
                  font-size: 25px;
                  padding-bottom: 10px;
                }

                // Card description
                p {
                  color: #717861;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25px;
                  padding-bottom: 40px;
                }

                // Card footer (h3)
                h3 {
                  color: #2b3513;
                  font-size: 14px;
                  cursor: pointer;
                  position: absolute;
                  bottom: 20px;
                }
              }
            }
          }
        }

        // Full-width on mobile
        @include mobile-1 {
          width: 100%;
        }
      }

      // Right column for additional content or sidebar
      .right {
        width: 30%;

        @include mobile-1 {
          width: 100%;
          padding-top: 50px;
        }

        // Top section in the right column
        .top {
          border-left: 5px solid #2b3513;
          margin-bottom: 32px;
          padding-left: 10px;
        }

        // Sidebar heading
        h2 {
          color: #4a4a4a;
          font-size: 30px;
        }

        // Sidebar content wrapper
        .wrap {
          // Individual cards inside right column
          .card {
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 20px;

            // Button inside the card
            button {
              background: none;
              padding: 7px 20px;
              color: #2b3513;
              font-size: 10px;
              border: 1px solid #2b3513;
              border-radius: 40px;
              font-family: "Roboto";
              margin-bottom: 8px;
            }

            // Flex container for card content
            .flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;

              // Card title
              h3 {
                color: #2b3513;
                font-size: 16px;
                width: 90%;
              }

              // Icon or SVG next to card title
              svg {
                font-size: 16px;
                cursor: pointer;
              }
            }

            // Card description
            p {
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #4e5665;
              font-size: 14px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

//.album-gallery {
//  display: grid;
//  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
//  gap: 20px;
//  padding: 20px;
//  justify-items: center;
//}
.album-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 20px;
  width: 100vw;
  justify-items: center;
}

@media (max-width: 768px) {
  .album-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .album-gallery {
    grid-template-columns: 1fr;
  }
}

.album {
  background-color: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
}

.album:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.album-image {
  position: relative;
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center;
  border-bottom: 2px solid #ddd;
}

.image-stack {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 90%;
  height: 90%;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform: rotate(-2deg);
}

.album-title {
  padding: 15px 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-transform: capitalize;
}

.gallery-title {
  font-size: 2.5rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 2px solid #333;
  display: inline-block;
  padding-bottom: 10px;
}

//.pagination-container {
//  display: flex;
//  justify-content: center;
//  gap: 20px;
//  margin-top: 20px;
//}

.pagination-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px;
}

.pagination-button:hover {
  background-color: #555;
}
