.Hostcontainer{
    width: 100%;

}
.Hostcontainer .Hostheader{
    width: 100%;
    margin-bottom: 50px;
}
.Hostcontainer .Hostheader img{
    width: 100%;
    height: 100%;
}

.Hostcontainer .Hosttext{
    text-align: center;
    /* border: 1px solid red; */
    width: 85%;
    margin: 0 auto;
}

.Hostcontainer .Hosttext p{
    font-size: 18px;
}