.Generalcontainer {
  width: 100%;
}
.Generalcontainer .text {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 150px;
  /* border: 1px solid red; */
  padding: 10px;
}

.Generalcontainer .text .generaltext h1 {
  font-size: 35px;
}
.Generalcontainer .text .generaltext {
  width: 40%;
}

.Generalcontainer .text .generalimage {
  width: 50%;
}
.Generalcontainer .text .generalimage img {
  width: 100%;
  height: 100%;
}

.Generalcontainer .daysbuttoncontainer {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.Generalcontainer .days .inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #0c1b4d1a;
  padding: 10px;
  margin-bottom: 100px;
}

.inside-centered {
  text-align: center;
}

.Generalcontainer .days .inside p {
  /* width: 20%; */
  font-weight: 500;
}
.Generalcontainer .days .inside h2 {
  /* width: 40%; */
  font-weight: 500;
  color: #2b3513;
}

@media screen and (max-width: 900px) {
  .Generalcontainer .text {
    flex-direction: column-reverse;
    width: 95%;
  }
  .Generalcontainer .text .generaltext {
    width: 80%;
  }

  .Generalcontainer .text .generalimage {
    width: 80%;
  }
}

@media screen and (max-width: 680px) {
  .Generalcontainer .days .inside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    text-align: left;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #0c1b4d1a;
    padding: 10px;
    margin-bottom: 100px;
  }
  .Generalcontainer .days .inside h2 {
    width: 100%;
    /* border: 1px solid red; */
  }
  .Generalcontainer .days .inside p {
    width: 100%;
    font-weight: 500;
    /* border: 1px solid red; */
  }
}
@media screen and (max-width: 500px) {
  .Generalcontainer .days .inside {
    grid-template-columns: 1fr;
  }
}
