@import "../../Styles/resources";

.publications {
  .hero_image {
    min-height: 50vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../images/new-images/InsightBgImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
    .cover {
      width: 100%;
      max-width: 800px;
      h2 {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 10px;
        @include mobile-3 {
          font-size: 14px;
        }
        text-transform: capitalize;
      }
      h1 {
        color: #fff;
        font-size: 50px;
        font-weight: 400;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
      p {
        color: #fff;
        font-size: 30px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }

  .news_main {
    display: flex;
    justify-content: center;
    font-family: "Roboto";
    .cover {
      width: 100%;
      max-width: 1300px;
      display: flex;
      justify-content: space-between;
      padding: 100px 30px;
      flex-wrap: wrap;
      @include mobile-1 {
        padding: 100px 20px;
      }
      .left {
        width: 65%;
        height: 100%;
        img {
          width: 100%;
          height: 70vh;
          object-fit: cover;
        }
        .sect_wrap {
          display: flex;
          justify-content: center;
          .coverr {
            width: 100%;
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .card {
              width: 48%;
              margin-bottom: 30px;
              background: #fff;
              position: relative;
              @include mobile-1 {
                width: 45%;
              }
              @include mobile-2 {
                width: 100%;
              }
              img {
                width: 100%;
              }
              .text {
                padding: 10px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h2 {
                  font-weight: 500;
                  color: #63c1cf;
                  font-size: 25px;
                  padding-bottom: 10px;
                }
                p {
                  color: #717861;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25px;
                  padding-bottom: 40px;
                }
                h3 {
                  color: #2b3513;
                  font-size: 14px;
                  cursor: pointer;
                  position: absolute;
                  bottom: 20px;
                }
              }
            }
          }
        }
        @include mobile-1 {
          width: 100%;
        }
      }
      .right {
        width: 30%;
        @include mobile-1 {
          width: 100%;
          padding-top: 50px;
        }
        .view-paid {
          margin: 20px 0px;
          color: #2b3513;
          cursor: pointer;
          font-weight: 600;
        }
        .top {
          border-left: 5px solid #2b3513;
          margin-bottom: 32px;
          padding-left: 10px;
        }
        h2 {
          color: #4a4a4a;
          font-size: 30px;
        }
        .wrap {
          .card {
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 20px;
            button {
              background: none;
              padding: 7px 20px;
              color: #2b3513;
              font-size: 10px;
              border: 1px solid #2b3513;
              border-radius: 40px;
              font-family: "Roboto";
              margin-bottom: 8px;
            }
            .flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 10px;
              h3 {
                color: #2b3513;
                font-size: 16px;
                width: 90%;
              }
              svg {
                font-size: 16px;
                cursor: pointer;
              }
            }
            p {
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #4e5665;
              font-size: 14px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
