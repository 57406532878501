@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$background: #fff;
$backgroundl: #f4f4f4;
$textcolor1: #000;
$textcolor2: #fb8122;
$textcolor3: #000;
$mobile-1: 1100px;
$mobile-2: 700px;
$mobile-3: 600px;
$mobile-4: 420px;
$mobile-5: 650px; //
$mobile-6: 800px;
$seaweed-green: #2b3513;

@mixin flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin mobile-1 {
  @media (max-width: $mobile-1) {
    @content;
  }
}
@mixin mobile-2 {
  @media (max-width: $mobile-2) {
    @content;
  }
}
@mixin mobile-3 {
  @media (max-width: $mobile-3) {
    @content;
  }
}
@mixin mobile-4 {
  @media (max-width: $mobile-4) {
    @content;
  }
}
@mixin mobile-5 {
  @media (min-height: $mobile-5) {
    @content;
  }
}
@mixin mobile-1120px {
  @media (max-width: 1120px) {
    @content;
  }
}
@mixin mobile-900px {
  @media (max-width: 1120px) {
    @content;
  }
}
@mixin mobile-6 {
  @media (max-width: $mobile-6) {
    @content;
  }
}
@mixin mobile-1300 {
  @media (max-width: 1300px) {
    @content;
  }
}
