@import "../../Styles//resources";
.sectoral {
  font-family: "Roboto";
  .hero_image {
    // height: 60vh;
    // max-height: 424px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../../images/Group\ 61.png");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    padding: 50px;
    @include mobile-3 {
      padding: 50px 20px;
    }
    &.diff {
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url("../../images/Group\ 62.png");
      background-size: cover;
      background-position: center;
    }
    .cover {
      h2 {
        color: #2b3513;
        font-weight: 500;
        font-size: 16px;
        padding-bottom: 40px;
        @include mobile-3 {
          font-size: 14px;
        }
      }
      h1 {
        color: #fff;
        font-size: 60px;
        font-weight: 400;
        padding-bottom: 15px;
        @include mobile-3 {
          font-size: 30px;
        }
      }
      p {
        color: #fff;
        font-size: 25px;
        @include mobile-3 {
          font-size: 20px;
        }
      }
    }
  }
  .sect-body {
    display: flex;
    justify-content: center;
    background-color: #f3f4f7;
    .cover {
      width: 100%;
      max-width: 1200px;
      padding: 20px 50px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mobile-2 {
        padding: 50px 20px;
      }

      .about {
        h1 {
          font-weight: 400;
          margin: 20px 0px;
        }

        p {
          margin: 10px 0px;
          font-size: 20px;
        }
      }

      .objectives {
        padding: 50px;
        background-color: #2b3513;
        color: #fff;
        margin: 50px -50px;
        width: 100vw;

        @include mobile-2 {
          margin: 50px 0px;
        }

        .obj-left-text {
          margin: 30px 0px;
          font-size: 18px;
        }

        h1 {
          font-weight: 40px;
          font-size: 40px;
          text-align: center;
          font-weight: 400;

          margin: 20px 0px;
        }

        .obj-items {
          display: grid;
          grid-template-columns: auto auto;
          gap: 40px;

          &.centered {
            grid-template-columns: auto;
          }

          .flex {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;

              @include mobile-4 {
                display: none;
              }
            }

            p {
              line-height: 25px;
            }
          }

          @include mobile-2 {
            grid-template-columns: auto;
          }
        }
      }

      .thrust {
        margin: 50px 0px;

        h1 {
          margin: 20px 0px 50px 0px;
        }

        h3 {
          margin: 20px 0px;
        }

        p {
          margin: 20px 0px;
        }
      }

      .the-board {
        margin: 50px 0px;

        @include mobile-4 {
          overflow-x: auto;
        }

        .board-header {
          margin: 20px 0px;
        }

        .board-items {
          display: grid;
          grid-template-columns: auto auto;
          gap: 30px;
          margin: 60px 0px;

          @include mobile-6 {
            grid-template-columns: auto;
          }

          .board-item {
            display: flex;
            align-items: center;

            .board-img {
              width: 200px;
              height: 200px;
              margin-right: 10px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .board-text {
              h1 {
                font-weight: 400;
                color: #2b3513;
                margin: 10px 0px;
              }
              p {
                font-size: 16px;
              }
            }
          }
        }
      }

      .benefits {
        margin: 40px 0px;

        h1 {
          font-size: 35px;
          margin: 30px 0px;
          font-weight: 500;
          text-align: center;
        }

        .benefit-items {
          display: grid;
          grid-template-columns: auto auto;
          gap: 40px;

          .flex {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;

              @include mobile-4 {
                display: none;
              }
            }
          }

          @include mobile-2 {
            grid-template-columns: auto;
          }

          .cycle {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 200px;
            }
          }

          .benefit-blue-p {
            margin: 10px 0px;
            color: #09444d;
            font-weight: 500;
            text-align: center;
          }
        }
      }
      .head-h1 {
        font-size: 30px;
        color: #000000;
        line-height: 140%;
        padding-bottom: 20px;
      }
      .services-header {
        font-weight: 400;
        margin: 20px 0px;
        text-align: center;
        width: 100%;
      }
      .service-header {
        margin: 30px 0px;
        color: #2b3513;
      }
      .wrap-g {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 50px 0px;
        .card {
          width: 48%;
          padding-bottom: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include mobile-3 {
            width: 100%;
          }
          button {
            cursor: pointer;
          }
          .flex {
            display: flex;
            padding-bottom: 20px;
            align-items: center;
            img {
              width: 28px;
            }
            h2 {
              color: #2b3513;
              font-size: 20px;
              padding-left: 10px;
            }
          }
          .main-img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            margin: 20px 0px;
          }
          button {
            width: fit-content;
            border: 2.77236px solid #555d42;
            border-radius: 693.09px;
            padding: 15px 25px;
            margin-top: 20px;
          }
        }
      }
      .longg {
        h1 {
          color: #2b3513;
          font-size: 20px;
          padding-bottom: 10px;
        }
        p {
          color: #2b3513;
          line-height: 30px;
        }
      }
      .events {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 30px;
        @include mobile-1 {
          padding: 30px;
        }
        .card {
          margin-bottom: 20px;
          width: 30%;
          @include mobile-3 {
            width: 43%;
          }
          @include mobile-4 {
            width: 100%;
          }
          img {
            width: 100%;
          }
          p {
            color: #000022;
            font-size: 12px;
            padding-left: 5px;
          }
          .icons {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            svg {
              font-size: 15px;
            }
          }
          h2 {
            color: #000022;
            font-size: 14px;
            padding-bottom: 10px;
          }
          .buttons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            button {
              padding: 6px 20px;
              background: #717861;
              border-radius: 5px;
              border: 1px solid #717861;
              color: #fff;
              cursor: pointer;
              margin-right: 10px;
              &.light {
                background: none;
                color: #717861;
              }
            }
          }
        }
      }
      .extras {
        margin: 30px 0px;
        font-size: 14px;
      }
      .corpor {
        .flex {
          display: flex;
          padding-bottom: 20px;
          align-items: center;
          img {
            width: 28px;
          }
          h2 {
            color: #000000;
            font-size: 25px;
            padding-left: 10px;
          }
        }
        p {
          font-size: 18px;
          color: #000000;
          line-height: 140%;
        }
      }
      .house {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        padding-top: 30px;

        .card {
          width: 48%;
          padding-bottom: 40px;
          @include mobile-3 {
            width: 100%;
          }
          h1 {
            color: #2b3513;
            font-size: 20px;
            padding-bottom: 10px;
          }
          p {
            color: #2b3513;
            line-height: 30px;
          }
        }
      }
    }
  }
  iframe {
    margin: 50px 0;
  }
}

.corporate-thrust {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
  max-width: 100vw;
  margin: 0 auto;

  h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2rem;
    font-weight: bold;
  }

  section {
    margin-bottom: 40px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5;
    }
  }

  .vision,
  .mission,
  .core-values {
    border-left: 4px solid #007bff;
    padding-left: 20px;
  }

  .core-values {
    border-color: #28a745;
  }
}

.board {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;

  h1 {
    font-size: 2rem;
    text-align: left;
    margin-bottom: 30px;
    font-weight: bold;
  }

  .board-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .board-member {
      display: flex;
      align-items: center;
      width: 48%;
      margin-bottom: 40px;

      &__image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &__placeholder {
          width: 100%;
          height: 100%;
          background-color: #e0e0e0;
          border-radius: 50%;
        }
      }

      &__info {
        h3 {
          margin: 0;
          font-size: 1.2rem;
          color: #4f6d41;
        }

        p {
          margin: 5px 0 0;
          font-size: 1rem;
          color: #333;
        }
      }
    }
  }
}

.services {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .service-section {
    margin-bottom: 40px;

    h2 {
      font-size: 1.6rem;
      margin-bottom: 15px;
      font-weight: bold;
      color: #2b3514;
      //color: #4f6d41;
    }

    .service-items {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .service-item {
        display: flex;
        width: 48%;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;

        &__image {
          width: 120px;
          height: 120px;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__details {
          padding: 10px;
          flex: 1;

          h3 {
            font-size: 1.2rem;
            color: #333;
          }

          p {
            margin: 5px 0;
            font-size: 0.9rem;
            color: #666;
          }

          .view-more {
            padding: 5px 10px;
            font-size: 0.9rem;
            background-color: #4f6d41;
            color: white;
            border: none;
            cursor: pointer;
            border-radius: 4px;
          }
        }
      }
    }

    .service-content {
      margin: 15px 0;
      font-size: 1rem;
      line-height: 1.5;
    }

    .partners {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;

      .partner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(33.33% - 20px);
        text-align: center;

        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
          margin-bottom: 10px;
        }

        p {
          margin: 0 0 10px;
          font-size: 1rem;
        }

        .view-more {
          padding: 5px 10px;
          font-size: 0.9rem;
          background-color: #4f6d41;
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 4px;
        }
      }
    }
  }
}
.view-more-button {
  border: 2px solid black;
  border-radius: 8px;
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    background-color: black;
    color: white;
  }

  &:focus {
    outline: none;
  }
}