.homeimages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
}

@media screen and (max-width: 753px) {
  .homeimages {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
@media screen and (max-width: 650px) {
  .homeimages {
    grid-template-columns: 1fr;
  }
}

.Speakercontainer {
  width: 100%;
}
.Speakercontainer .Speakerheader {
  width: 1-0%;

  margin-bottom: 50px;
  background: #2b3513;
  text-align: center;
}
.Speakercontainer .Speakerheader .Speakerheadercontainer {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 50px;
  align-items: center;
}
.Speakercontainer .Speakerheader .white {
  border: 1px solid white;
  width: 40px;
  margin: 0 auto;
}

.Speakercontainer .Speakerheader h3 {
  color: #ffffff;
  font-weight: 400;

  /* width: 25%; */
  font-size: 25px;
  margin: 0 auto;
  /* border-bottom: 1px solid white; */
}
.Speakercontainer .Speakerheader .Speakerheadercontainer .Speakerheadertext {
  color: #ffffff;
  /* font-family: Inter; */
  width: 60%;
  text-align: left;
}
.Speakercontainer .Speakerheader .Speakerheadercontainer .Speakerheadertext h1 {
  font-size: 50px;
}
.Speakercontainer .Speakerheader .Speakerheadercontainer .Speakerheaderimage {
  width: 30%;
}

.Speakercontainer
  .Speakerheader
  .Speakerheadercontainer
  .Speakerheaderimage
  img {
  width: 100%;
  height: 100%;
  margin-right: 15px;

  background: #2b3513;
}

.Speakercontainer .Speakerheader img {
  width: 100%;
  height: 100%;
}

.Speakercontainer .Speakertext {
  text-align: center;
  /* border: 1px solid red; */
  width: 85%;
  margin: 0 auto;
}

.Speakercontainer .Speakertext p {
  font-size: 18px;
}
@media screen and (max-width: 700px) {
  .Speakercontainer .Speakerheader .Speakerheadercontainer {
    flex-direction: column-reverse;
    width: 80%;
    padding: 50px;
    align-items: center;
  }
  .Speakercontainer .Speakerheader .Speakerheadercontainer .Speakerheaderimage {
    width: 80%;
  }
  .Speakercontainer .Speakerheader .Speakerheadercontainer .Speakerheadertext {
    width: 80%;
  }
}
