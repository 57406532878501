@import "../../Styles/resources";

.applicationPortal {
  .logo {
    padding: 0px 40px;
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
  .header {
    margin: 20px 0px;
    text-align: center;
  }
  .status-track {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 60px 0px;

    @include mobile-6 {
      flex-direction: column;
      gap: 30px;
    }
    .status-separator {
      height: 2px;
      background-color: #000;
      width: 200px;
      margin: 20px 10px;
    }
    .status-text-con {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        margin: 30px 0px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
    }
    .status-item {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: #010001;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      &.dotted {
        border: 1px dashed #a0a0a0;
        background-color: #fff;
        color: #010001;
      }
    }
  }
  .application-container {
    padding: 30px 80px 30px 0px;
    display: flex;
    gap: 40px;
    @include mobile-6 {
      flex-direction: column;
      padding: 20px 20px 20px 20px;
    }
    .membership-admission {
      color: #2c3513;
      flex: 3;
      .has-paid {
        h1 {
          font-size: 25px;
          text-align: center;
          font-weight: 600;
        }

        .header {
          font-size: 20px;
          font-weight: 500;
        }

        .payment-summary {
          background-color: #ddd;
          padding: 10px;
          text-align: center;
          border-radius: 5px;
          margin: 10px 0px;

          small {
            font-size: 13px;
          }
          p {
            font-size: 20px;
            .darken {
              font-weight: 500;
              margin-right: 10px;
            }
          }

          &.no-bg {
            background-color: #fff;
            button:disabled {
              background-color: #a0a0a0;
            }
            button {
              border: none;
              background-color: #2c3513;
              color: #fff;
              font-weight: 400;
              font-size: 20px;
              padding: 10px 20px;
              border-radius: 10px;
              outline: none;
              cursor: pointer;
            }
          }
        }
      }
      .not-paid {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
          width: 80%;
          text-align: center;
          font-size: 25px;
          font-weight: 500;
        }
      }
    }
    .side-navigation {
      flex: 1;
      .side-navigation-btn {
        display: flex;
        // justify-content: center;
        // align-items: center;
        margin: 20px 0px;
        color: #010001;
        padding: 30px;
      }
      .green {
        background-color: #2c3513;
        color: #fff;
      }
    }
    .delete-btn {
      margin: 20px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        outline: none;
        background-color: #2c3513;
        border: none;
        padding: 10px 20px;
        color: #fff;
      }
    }
    form {
      .installed-style {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin: 20px 0px;

        label {
          display: flex;
          flex-direction: row;
          align-items: center;

          input {
            margin: 0px 10px;
            cursor: pointer;
          }
        }
      }
      flex: 2;
      .generic-text {
        margin: 20px 0px;
      }
      .subscription-rates {
        height: 600px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .each-half {
        flex: 1;
        .image-label {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: space-evenly;
          margin: 30px 0px;
          img {
            width: 200px;
            height: 100px;
            object-fit: contain;
          }
        }

        .image-radio {
          width: 50px;
          height: 50px;
        }
      }
      h1 {
        margin: 30px 0px;
        color: #2c3513;
      }
      h5 {
        margin: 30px 0px;
        color: red;
      }
      .radio-btn {
        display: flex;
        align-items: center;
        flex-direction: row;
        .radio-input {
          margin-left: 10px;
        }
      }
      label {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        color: #2c3513;

        small {
          font-weight: 700;
        }
        input {
          padding: 10px 20px;
          outline: none;
          border: 1px solid #a0a0a0;
          border-radius: 10px;
          margin: 20px 0px;
        }
        select {
          padding: 10px 20px;
          outline: none;
          border: 1px solid #a0a0a0;
          border-radius: 10px;
          margin: 20px 0px;
        }

        textarea {
          padding: 10px 20px;
          outline: none;
          border: 1px solid #a0a0a0;
          border-radius: 10px;
          margin: 20px 0px;
          height: 200px;
        }
      }
      .half-input {
        display: flex;
        align-items: center;
        gap: 20px;

        &.spaced {
          justify-content: space-between;
        }

        label {
          width: 100%;
        }
        .upload-img-con {
          flex: 1;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          color: #2c3513;
          .upload-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-radius: 10px;
            border: 1px dashed #a0a0a0;
            margin: 20px 0px;

            img {
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }
        }

        &.center {
          label {
            font-weight: 700;
            font-size: 18px;
          }
        }

        &.left {
          align-items: center;

          @include mobile-2 {
            align-items: flex-start;
          }
        }

        @include mobile-2 {
          flex-direction: column;
        }
      }
      .btn-con {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;

        &.align-center {
          flex-direction: row;
          justify-content: center;
          gap: 40px;

          @include mobile-6 {
            flex-direction: column;
          }
        }
        button {
          padding: 10px 20px;
          color: #fff;
          background-color: #2c3513;
          border-radius: 30px;
          outline: none;
          border: none;
          font-size: 16px;
          margin: 20px 0px;
          cursor: pointer;
        }
      }

      .add-more {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0px;

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin-bottom: 10px;
          cursor: pointer;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}
