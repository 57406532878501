.join-new-page {
  .join-us {
    color: #fff;
    background-color: #343a40;
    padding: 30px 50px;
    margin: 50px 0px;
    text-align: center;

    h1 {
      font-weight: 400;
      margin: 30px 0px;
    }
    p {
      line-height: 25px;
    }
  }
  .eligibilty {
    padding: 30px;
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: 400;
      color: #2b3513;
      margin: 20px 0px;
    }
    h4 {
      font-weight: 400;
      color: #2b3513;
      margin: 10px 0px;
    }

    form {
      display: flex;
      flex-direction: column;
      label {
        input {
          margin-right: 10px;
        }
      }

      button {
        border-radius: 20px;
        padding: 10px 20px;
        background-color: #2b3513;
        outline: none;
        margin: 30px 0px;
        border: none;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .prospective {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #343a40;
    margin: 50px 0px;
    color: #fff;
    padding: 30px;
    h1 {
      font-weight: 400;
      margin: 30px 0px;
    }
    p {
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      input {
        width: 400px;
        padding: 20px;
        outline: none;
        border: 1px solid #fff;
        margin: 20px 0px;
        margin-top: 5px;
        color: #fff;
        background-color: transparent;
        &::placeholder {
          color: #fff;
        }
      }
      .padded-form-container {
        width: 400px;
        outline: none;
        color: #fff;
        border: 1px solid #fff;
        background-color: transparent;
        display: flex;
        align-items: center;
        padding-left: 20px;
        .padded-text-left {
          font-size: 14px;
        }
        .padded-input {
          padding-left: 10px;
          border: none;
          margin: 0px;
        }
      }
      button {
        border-radius: 20px;
        padding: 10px 20px;
        background-color: #2b3513;
        outline: none;
        margin: 30px 0px;
        border: none;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
