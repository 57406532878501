@import "../../Styles/resources";

.mrc-contact {
  .contact-us {
    padding: 20px 50px;
    margin: 80px 0px;
    display: flex;
    gap: 20px;

    @include mobile-6 {
      flex-direction: column;
    }
    .left {
      flex: 1;

      .header {
        font-size: 40px;
        font-weight: 300;
        color: #2c3513;
        margin: 30px 0px;
        span {
          font-weight: 700;
        }
      }

      form {
        label {
          font-size: 14px;
          display: flex;
          flex-direction: column;
          color: #777777;
          input {
            padding: 10px 20px;
            outline: none;
            border: 1px solid #ced4da;
            margin: 20px 0px;
          }

          textarea {
            padding: 10px 20px;
            outline: none;
            border: 1px solid #ced4da;
            margin: 20px 0px;
            height: 300px;
          }
        }
        .half-input {
          display: flex;
          align-items: center;
          gap: 20px;

          @include mobile-2 {
            flex-direction: column;
          }
        }
        button {
          padding: 10px 20px;
          color: #fff;
          background-color: #2c3513;
          border-radius: 10px;
          outline: none;
          border: none;
          margin: 20px 0px;
        }
      }
    }
    .right {
      flex: 1;

      .objectives {
        padding: 0px 50px;
        margin: 50px 0px;
        border-bottom: 1px solid #ced4da;

        @include mobile-2 {
          padding: 0px 10px;
        }

        &.no-btm-bd {
          border-bottom: none;
        }

        .header {
          font-weight: 300;
          color: #2c3513;
          margin: 30px 0px;
          font-size: 20px;
          span {
            font-weight: 700;
          }
        }
        p {
          color: #777777;
          font-weight: 300;
          margin: 10px 0px;
        }

        .obj-item {
          display: flex;
          align-items: center;
          margin: 20px 0px;
          div {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #2c3513;
          }

          span {
            margin-left: 10px;
            flex: 1;
            word-break: break-all;
          }
          .span-bold {
            color: #2c3513;
            font-weight: 700;
          }

          .span-light {
            font-weight: 300;
          }
        }
      }

      .image-banner {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;

        div {
          width: 150px;
          height: 100px;
          background-color: #dddddd;
          padding: 10px;
          border-radius: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
