@import "../../../Styles/resources";

.paid-publication-preview {
  color: #2b3513;
  background-color: #fff;
  padding: 20px;
  width: 500px;
  height: 500px;
  overflow-y: auto;
  @include mobile-6 {
    width: 300px;
  }
  .close-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: #fff;
      padding: 5px;
      cursor: pointer;
      outline: none;
      font-weight: 500;

      border: 1px solid #2b3513;
    }
  }
  img {
    height: 200px;
    object-fit: contain;
    width: 100%;
    margin: 30px 0px 20px 0px;
  }

  .text-section {
    text-align: center;
    .darken {
      font-size: 24px;
      margin: 10px 0px;
    }
  }
}
