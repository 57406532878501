@import "../../Styles/resources";

.purchase-application {
  .purchase-container {
    padding: 20px 60px;
    @include mobile-6 {
      padding: 20px 30px;
    }
    .header {
      font-size: 25px;

      &.center {
        text-align: center;
      }
    }
    .split-page {
      display: flex;
      gap: 40px;

      @include mobile-6 {
        flex-direction: column;
      }
    }
    form {
      .each-half {
        flex: 1;

        .image-label {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: space-evenly;
          margin: 30px 0px;
          img {
            width: 200px;
            height: 100px;
            object-fit: contain;
          }
        }

        .image-radio {
          width: 50px;
          height: 50px;
        }
      }
      h1 {
        margin: 30px 0px;
        color: #2c3513;
      }
      h5 {
        margin: 30px 0px;
        color: #2c3513;
      }
      .radio-btn {
        display: flex;
        align-items: center;
        flex-direction: row;
        .radio-input {
          margin-left: 10px;
        }
      }
      label {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        color: #2c3513;
        small {
          font-weight: 700;
        }
        .padded-text {
          display: flex;
          align-items: center;
          border: 1px solid #a0a0a0;
          padding: 0px 0px 0px 20px;
          margin: 20px 0px;
          border-radius: 10px;

          input {
            margin: 0px;
            width: 100%;
            border: none;
          }
        }
        input {
          padding: 10px 20px;
          outline: none;
          border: 1px solid #a0a0a0;
          border-radius: 10px;
          margin: 20px 0px;
        }

        textarea {
          padding: 10px 20px;
          outline: none;
          border: 1px solid #a0a0a0;
          border-radius: 10px;
          margin: 20px 0px;
          height: 200px;
        }
      }
      .half-input {
        display: flex;
        align-items: center;
        gap: 20px;

        label {
          width: 100%;
        }

        &.center {
          label {
            font-weight: 700;
            font-size: 18px;
          }
        }

        &.left {
          align-items: center;

          @include mobile-2 {
            align-items: flex-start;
          }
        }

        @include mobile-2 {
          flex-direction: column;
        }
      }
      .btn-con {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        button {
          padding: 10px 20px;
          color: #fff;
          background-color: #2c3513;
          border-radius: 30px;
          outline: none;
          border: none;
          font-size: 16px;
          margin: 20px 0px;
          cursor: pointer;
        }
      }
    }
  }
}
