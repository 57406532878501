@import "../../../Styles/resources";

.purchase-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 20px;
  margin: 30px 0px;
  background-color: #343a40;

  h1 {
    font-weight: 700;
    font-size: 35px;
    margin: 20px 0px;
  }

  p {
    font-size: 16px;
    margin: 10px 0px;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.purchase-warn-banner {
  margin: 20px 0px;
  width: 70%;
  padding: 30px;
  background-color: #63c1cf33;
  @include mobile-6 {
    width: 100%;
  }
  p {
    color: #01000180;
  }
}

.small-red-text {
  color: #990000;
  margin-bottom: -10px;
}

.processing-payment {
  width: 300px;
  border-top: 3px solid #2c3513;
  padding: 30px;
  h1 {
    color: #505050;
    margin: 30px 0px;
    font-weight: 500;
  }
  p {
    color: #505050;
    margin: 20px 0px;
  }
}
