.about-dropdown {
  position: absolute;
  background-color: #fff;
  z-index: 50;
  border-top: 5px solid #1f260d;
  border-radius: 5px;
  overflow: hidden;

  width: 250px;

  top: 70px;
  left: -2%;
  .dropdown-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 700;
      color: #1f260d;

      a {
        text-decoration: none;
        color: #1f260d;

        &:hover {
          color: #e36159;
        }
      }
    }
  }
}

.service-dropdown {
  position: absolute;
  background-color: #fff;
  z-index: 50;
  border-top: 5px solid #1f260d;
  border-radius: 5px;
  overflow: hidden;

  width: 200px;

  top: 70px;
  left: calc(100% - 87%);
  .extra {
    border-top: 1px solid #1f260d;
    border-bottom: 1px solid #1f260d;
  }
  .dropdown-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 700;
      color: #1f260d;

      a {
        text-decoration: none;
        color: #1f260d;

        &:hover {
          color: #e36159;
        }
      }
    }
  }
}

.insight-dropdown {
  position: absolute;
  background-color: #fff;
  z-index: 50;
  border-top: 5px solid #1f260d;
  border-radius: 5px;
  overflow: hidden;

  width: 250px;

  top: 70px;
  left: calc(100% - 78%);
  .dropdown-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 700;
      color: #1f260d;

      a {
        text-decoration: none;
        color: #1f260d;

        &:hover {
          color: #e36159;
        }
      }
    }
  }
}

.membership-dropdown {
  position: absolute;
  background-color: #fff;
  z-index: 50;
  border-top: 5px solid #1f260d;
  border-radius: 5px;
  overflow: hidden;

  width: 300px;

  top: 70px;
  left: calc(100% - 67%);
  .dropdown-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 700;
      color: #1f260d;

      a {
        text-decoration: none;
        color: #1f260d;

        &:hover {
          color: #e36159;
        }
      }
    }
  }
}

.structure-dropdown {
  position: absolute;
  background-color: #fff;
  z-index: 50;
  border-top: 5px solid #1f260d;
  border-radius: 5px;
  overflow: hidden;

  width: 200px;

  top: 70px;
  left: calc(100% - 50%);

  .extra {
    border-top: 1px solid #1f260d;
    border-bottom: 1px solid #1f260d;
  }
  .dropdown-item {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 700;
      color: #1f260d;

      a {
        text-decoration: none;
        color: #1f260d;

        &:hover {
          color: #e36159;
        }
      }
    }
  }
}
