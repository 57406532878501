@import "../../Styles/resources";
.Subscribe {
  position: fixed;

  height: 100vh;
  background: rgba(2, 2, 2, 0.3);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  .cover {
    position: relative;
    background-color: #fff;
    width: 90%;
    margin: 30px;
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .left {
      width: 50%;
      @include mobile-3 {
        display: none;
      }
      img {
        width: 100%;
      }
    }
    .right {
      width: 50%;
      padding: 20px;
      text-align: center;
      @include mobile-3 {
        width: 100%;
      }
      .huo {
        h1 {
          font-size: 27px;
          font-weight: 500;
          color: #2b3513;
          padding-bottom: 20px;
        }
        input {
          background: rgba(255, 255, 255, 0.24);
          border: 1px solid #cce2ec;
          border-radius: 16px;
          padding: 10px 20px;
          width: 100%;
          &::placeholder {
            color: #000;
            font-size: 16px;
            font-weight: 400;
          }
        }
        button {
          margin-top: 20px;
          width: 100%;
          background: #2b3513;
          border-radius: 16px;
          padding: 15px 0;
          border: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
