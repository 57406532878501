body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-button-next {
  color: #fff !important;
  height: 50px !important;
  width: 50px !important;
  background-color: #2b3513;
}
.swiper-button-prev {
  color: #fff !important;
  height: 50px !important;
  width: 50px !important;
  background-color: #2b3513;
}

.swiper-button-next::before,
.swiper-button-next::after,
.swiper-button-prev::before,
.swiper-button-prev::after {
  font-size: 14px !important;
  font-weight: 700 !important;
}
