@import "../../../Styles/resources";

.dash-nav {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;

  .nav-items {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}
