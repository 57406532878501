@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./Styles/resources";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.home {
  .modal {
    position: fixed;
    height: 100vh;
    background: rgba(2, 2, 2, 0.3);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .cover {
      background-color: #fff;
      width: 90%;
      margin: 30px;
      max-width: 700px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .left {
        width: 50%;
        @include mobile-3 {
          display: none;
        }
        img {
          width: 100%;
        }
      }
      .right {
        width: 50%;
        padding: 20px;
        text-align: center;
        @include mobile-3 {
          width: 100%;
        }
        .huo {
          h1 {
            font-size: 27px;
            font-weight: 500;
            color: #2b3513;
            padding-bottom: 20px;
          }
          input {
            background: rgba(255, 255, 255, 0.24);
            border: 1px solid #cce2ec;
            border-radius: 16px;
            padding: 10px 20px;
            width: 100%;
            &::placeholder {
              color: #000;
              font-size: 16px;
              font-weight: 400;
            }
          }
          button {
            margin-top: 20px;
            width: 100%;
            background: #cce2ec;
            border-radius: 16px;
            padding: 10px 0;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }
}
